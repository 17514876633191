@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {  
  scrollbar-width: 5px;
  scrollbar-color: #2b06ff;
  /* color: rgb(90, 37, 37) !important; */
  
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 17px;
}

*::-webkit-scrollbar-track {
  background: #131313;
}

*::-webkit-scrollbar-thumb {
  background-color: #f3e9e9;
  border-radius: 10px;
  /* visibility: hidden; */
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}




.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid #4e57d4;
  animation: spinner-bulqg1 0.8s infinite linear alternate,
    spinner-oaa3wk 1.6s infinite linear;
}


@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}



.form-input{
 outline: 1px;
 outline: #2b06ff;
 
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);

  padding: 10px;
  text-align: start !important;
  border-radius: 10px;
}

.product label{
  font-weight: 600;
}




.no-scroll {
  position: fixed;
  width: 100vw;
  height: 100vh;
}






















@media only screen and (max-width:639px) {
  .mobile{
   display: none;
  }
  .checkout{
    min-height: 80vh;
    overflow-y: scroll;
    
  }

/* Hide scrollbar for IE, Edge and Firefox */
.checkout {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.checkout::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

 
  .head-media{
   gap:4em;
  }
 }
